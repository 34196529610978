import React, { useState, useEffect } from "react";
import img from "../../assets/admin/assets/img/logo.png";
import { Link, useHistory } from "react-router-dom";
import { MDBBtn, MDBIcon, MDBBadge } from "mdb-react-ui-kit";
import "../../App.css";

import {
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-react-ui-kit";

import swal from "sweetalert";
import axios from "axios";

function Navbar() {
  const history = useHistory();
  const [cart, setCart] = useState([]);
  const [wish, setWish] = useState([]);
  const [registerInput, setUser] = useState([]);

  const [showBasic, setShowBasic] = useState(false);

  useEffect(() => {
    axios.get(`/api/view-cart`).then((res) => {
      if (res.data.status === 200) {
        setCart(res.data.cart);
      }
    });

    axios.get(`/api/view-wishlist`).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data.wish);
        setWish(res.data.wish);
      }
    });
  }, []);

  useEffect(() => {
    // if (!localStorage.getItem("auth_token")) {
    //   history.push("/");
    //   swal("Warning", "Login to goto Cart Page", "error");
    // }

    let isMounted = true;
   
    axios.get(`/api/getUserDetail`).then((res) => {
      if (res.data.status === 200) {
        setUser(res.data.user);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        history.push("/account");
      }
      // setLoading(false);
    });

    return () => {
      isMounted = false;
    };
  }, [history]);

  const logoutSubmit = (e) => {
    e.preventDefault();

    axios.post(`/api/logout`).then((res) => {
      if (res.data.status === 200) {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_name");
        swal("Success", res.data.message, "success");
        history.push("/");
      }
    });
  };

  var AuthButtons = "";
  if (!localStorage.getItem("auth_token")) {
    AuthButtons = (
      <ul className="navbar-nav">
        
        <li className="nav-item"></li>

        
        <MDBNavbarItem>
          <MDBDropdown>
            <MDBDropdownToggle tag="a" className="nav-link account_base" role="button">
              <span className="">
              <span class="icon-Lock-2 fs-4 "></span> 
                {'  '}
              </span>
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem link>
                <Link className="nav-link" to="/login">
                  <span>Sign In </span> <span class="icon-Arrow-LeftinCircle fs-4 float-end"></span>
                </Link>
              </MDBDropdownItem>
              <MDBDropdownItem link>
                <Link className="nav-link" to="/register">
                  Sign Up <span class="icon-Add-User fs-4 float-end"></span>
                </Link>
              </MDBDropdownItem>

              <MDBDropdownItem link>
                <Link className="nav-link" to="/register">
                  List Your Property
                </Link>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>
      </ul>
    );
  } else {
    AuthButtons = (
      <>
        {/* <li className="nav-item">
          <Link className="nav-link" to="/cart">
            Bookings
            {cart.length > 0 ? (
              <MDBBadge className="ms-2">{cart.length}</MDBBadge>
            ) : (
              " "
            )}
          </Link>
        </li> */}

        {/* <li className="nav-item">
          <Link className="nav-link" to="/wishlist">
            Wishlist
            {wish.length > 0 ? (
              <MDBBadge className="ms-2">{wish.length}</MDBBadge>
            ) : (
              " "
            )}
          </Link>
        </li> */}

        

        <li className="nav-item mr-2 ms-3">
          <Link className="nav-link" to="/cart">
          <span class="icon-Home fs-4 "></span> 
           
            {cart.length > 0 ? (
              <span class="badge rounded-pill badge-notification bg-danger">
              {cart.length}
            </span>
              
            ) : (
              " "
            )}
            
          </Link>
        </li>

        <ul className="navbar-nav">
        <li className="nav-item"></li>

        
        <MDBNavbarItem className="mr-3">
          <MDBDropdown>
        
            <MDBDropdownToggle tag="a" className="nav-link account_base" role="button">
            Hello {registerInput.firstname} {' '} {registerInput.lastname}
              <span className="">
              <MDBIcon icon='user-alt' className='ms-1' size='lg' /> 
                {'  '} 
              </span>
            </MDBDropdownToggle>
            <MDBDropdownMenu>
            <MDBDropdownItem link>
                <Link className="nav-link" to="/account">
                  My Account <span class="icon-User fs-4 float-end"></span> 
                </Link>
              </MDBDropdownItem>
              <MDBDropdownItem link>
              <Link className="nav-link" to="/account/wishlist">
              Wishlist 
              {wish.length > 0 ? (
                <>
                  <span class="icon-Heart fs-4 float-end"></span> 
            <span class="  badge rounded-pill badge-notification bg-danger float-end">
              {wish.length}
            </span> 
                </>
              ) : (
                " "
              )}
            </Link>
                </MDBDropdownItem>
              <MDBDropdownItem link>
              <Link className="nav-link" to="/account/notifications"> 
              Notifications <span class="icon-Bell fs-4 float-end"></span> 
            <span class="  badge rounded-pill badge-notification bg-danger ">
              1
            </span> 
          </Link>
              </MDBDropdownItem>

              <MDBDropdownItem link>
                <Link className="nav-link" to="/account/track">
                  Track My Bookings 
                </Link>
              </MDBDropdownItem>
              <MDBDropdownItem link>
                <Link className="nav-link" to="/account/reviews">
                  My Reviews <span class="icon-Star fs-4 float-end"></span> 
                </Link>
              </MDBDropdownItem>
              <MDBDropdownItem link>
                <Link className="nav-link" to="/account/faqs">
                  FAQs <span class="icon-Speach-BubbleDialog fs-4 float-end"></span> 
                </Link>
              </MDBDropdownItem>
              <MDBDropdownItem link>
              <Link className="nav-link"  onClick={logoutSubmit}>
                  LOGOUT <span class="icon-Arrow-RightinCircle fs-4 float-end"></span> 
                </Link>
              </MDBDropdownItem>
              
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>
        
      
      </ul>

      

      
      </>
    );
  }

  // <MDBBtn
  //           className="m-1 mr-1"
  //           style={{ backgroundColor: "#333333" }}
  //           onClick={logoutSubmit}
  //         >
  //           Logout
  //           <MDBIcon  fab icon='github' />
  //         </MDBBtn>

  return (
    <>
      {/*  */}
      <section className="background-1 color-6 py-2 fs--1 fw-600 font-1 hidden-md-down">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-10 align-self-center">
              <p className="mb-md-0 fs--1 font-1">
                No 11 Vanern Crescent, Abuja, Federal Capital Territory
                &nbsp;|&nbsp;
                <a className="color-8" href="tel:+17025908888">
                  Tel: +234-908-000-0591
                </a>{" "}
                &nbsp;
                {/* <a className="color-8 text-uppercase pl-2 ml-1" href="#map">
                                    <span className="fa fa-map-marker pr-2"></span>Maps &amp; Directions</a> */}
              </p>
            </div>
            <div className="col text-right">
              <Link className="btn btn-outline-warning btn-xs" to={!localStorage.getItem("auth_token") ? ("/register") : ("/search-homes")}>
                BOOK MY STAY
              </Link>
            </div>
          </div>
        </div>
      </section>

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark shadow sticky-top">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={img} width="60px" /> V-HOSPITALITY{" "}
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" to="/collections">
                  V-Properties
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active " to="/search-homes">
                  Search Homes
                </Link>
              </li>

              {/* <li className="nav-item">
                            <Link className="nav-link" to="/about">About</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">Contact</Link>
                        </li> */}
              {/* <li className="nav-item">
                            <Link className="nav-link" to="/login">Login</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/register">Register</Link>
                        </li> */}
              {AuthButtons}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
