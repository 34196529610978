import React , {useEffect, useState}from "react";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";



const USideBar = () => {

  return (
    <div className="row">
      <div className="col-md-6">
        <div style={{ minWidth: "22rem" }} className="mb-8">
        <h1 className="fs-4  p-4 mt-0 mb-0 text-white color-vhospitalilty-b ">
          My Account
        </h1>

        <Link to="/account" className="underlink_removal">
          <h1 className="fs-6 p-4 mt-0 mb-2 text-white color-vhospitalilty-b ">
            My V-Hospitality Profile
          </h1>
        </Link>
        <Link to="/account/wishlist" className="underlink_removal">
          <h1 className="fs-6 p-4 mt-0 mb-2 text-white color-vhospitalilty-b ">
            WishList
          </h1>
        </Link>
        <Link to="/account/notifications" className="underlink_removal">
          <h1 className="fs-6 p-4 mt-0 mb-2 text-white color-vhospitalilty-b ">
            Notifications
          </h1>
        </Link>
        <Link to="/account/track" className="underlink_removal">
          <h1 className="fs-6 p-4 mt-0 mb-2 text-white color-vhospitalilty-b ">
            Track My Bookings
          </h1>
        </Link>
        <Link to="/account/reviews" className="underlink_removal">
          <h1 className="fs-6 p-4 mt-0 mb-2 text-white color-vhospitalilty-b ">
            My Reviews
          </h1>
        </Link>
        <Link to="/account/faqs" className="underlink_removal">
          <h1 className="fs-6 p-4 mt-0 mb-2 text-white color-vhospitalilty-b ">
            FAQs
          </h1>
        </Link>
      </div>
      </div>
    </div>
  );
};

export default USideBar;
