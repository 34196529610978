// import Navbar from './ReusedComponents/NavBar';
import './App.css';


import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import React, { useState } from 'react';

// import MyFilter from './components/view_homes/Filter'
// import NavBar from './ReusedComponents/NavBar';
// import Footer from './ReusedComponents/Footer';


import Login from './components/frontend/auth/Login';
import Register from './components/frontend/auth/Register';
import Page403 from './components/errors/403';
import Page404 from './components/errors/404';
import axios from "axios";
// import AdminPrivateRoute from './AdminPrivateRoute';
// import useScript from './assets/UseScript';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import PublicRoute from './PublicRoute';
import UserRoute from './UserRoute';


axios.defaults.baseURL = `${process.env.REACT_APP_LOCALHOST_APP_URL}/`;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

axios.defaults.withCredentials = true;

// for logging out
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
})

// 



// app.listen(PORT, () => console.log(`listening on ${PORT}`));




const App = () => {

  //   App.use(express.static(__dirname));

  // App.get("/*", function(req, res) {
  //   res.sendFile(path.join(__dirname, "index.html"));
  // });

  //   (function() {
  //     var cors_api_host = 'cors-anywhere.herokuapp.com';
  //     var cors_api_url = 'http://localhost:8000/' + cors_api_host + '/';
  //     var slice = [].slice;
  //     var origin = window.location.protocol + '//' + window.location.host;
  //     var open = XMLHttpRequest.prototype.open;
  //     XMLHttpRequest.prototype.open = function() {
  //         var args = slice.call(arguments);
  //         var targetOrigin = /^https?:\/\/([^\/]+)/i.exec(args[1]);
  //         if (targetOrigin && targetOrigin[0].toLowerCase() !== origin &&
  //             targetOrigin[1] !== cors_api_host) {
  //             args[1] = cors_api_url + args[1];
  //         }
  //         return open.apply(this, args);
  //     };
  // })();



  // // Listen on a specific host via the HOST environment variable
  // var host = process.env.REACT_APP_LOCALHOST_APP_URL;
  // // Listen on a specific port via the PORT environment variable
  // var port = 8080;

  // var cors_proxy = require('cors');
  // cors_proxy.createServer({
  //     originWhitelist: [], // Allow all origins
  //     requireHeader: ['origin', 'x-requested-with'],
  //     removeHeaders: ['cookie', 'cookie2']
  // }).listen(host, function() {
  //     console.log('Running CORS Anywhere on ' + host + ':' + port);
  // });



  // app.use((req, res, next) => {
  //   res.header('Access-Control-Allow-Origin', '*');
  //   next();
  // });

  // app.get('/api/get-item/', (req, res) => {
  //   request(
  //     { url: 'https://testapp.vhospitalityservices.com' },
  //     (error, response, body) => {
  //       if (error || response.statusCode !== 200) {
  //         return res.status(500).json({ type: 'error', message: err.message });
  //       }

  //       res.json(JSON.parse(body));
  //     }
  //   )
  // });

  // app.get('/api/view-homes', (req, res) => {
  //   request(
  //     { url: 'https://testapp.vhospitalityservices.com' },
  //     (error, response, body) => {
  //       if (error || response.statusCode !== 200) {
  //         return res.status(500).json({ type: 'error', message: err.message });
  //       }

  //       res.json(JSON.parse(body));
  //     }
  //   )
  // });




  const [cartItems, setCartItems] = useState([])


  const handleHomeItem = (homeItem) => {
    const IfHomeExist = cartItems.find((item) => item.id === homeItem.id);
    if (IfHomeExist) {
      setCartItems(cartItems.map((item) => item.id === homeItem.id ?
        { ...IfHomeExist, quantity: IfHomeExist.quantity + 1 } : item))
    } else {
      setCartItems([...cartItems, { ...homeItem, quantity: 1 }])
    }
  }

  const removeHomeItem = (homeItem) => {
    const IfHomeItemExist = cartItems.find((item) => item.id === homeItem.id)
    if (IfHomeItemExist.quantity === 1) {
      setCartItems(cartItems.filter((item) => item.id !== homeItem.id))
    } else {
      setCartItems(cartItems.map((item) => item.id === homeItem.id ?
        { ...IfHomeItemExist, quantity: IfHomeItemExist.quantity - 1 } : item))
    }
  }

  return (

    <div className="App">
      <Router>
        <Switch>

          {/* <AdminPrivateRoute path="/admin" name="Admin" /> */}
          <UserRoute path="/account" name="Account" />
          <PublicRoute path="/" name="ViewCategory" />

          <Route exact path="/403" component={Page403} />

          <Route exact path="/404" component={Page404} />

          <Route path="/login">
            {localStorage.getItem('auth_token') ? <Redirect to='/' /> : <Login />}
          </Route>

          <Route path="/register">
            {localStorage.getItem('auth_token') ? <Redirect to='/' /> : <Register />}
          </Route>

        </Switch>
      </Router>
    </div>
  );

}

export default App;
