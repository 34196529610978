import React, { useState } from "react";

import swal from "sweetalert";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import "../../../App.css";
import Navbar from "../../../layouts/frontend/Navbar";

function Login() {
  const history = useHistory();
  const [loginInput, setLogin] = useState({
    email: "",
    password1: "",
    error_list: [],
  });

  const handleInput = (e) => {
    e.persist();
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: loginInput.email,
      password1: loginInput.password1,
    };

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/login", data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_name", res.data.username);
          swal("Success", res.data.message, "success");
          history.push("/");
          // if (res.data.role === "admin") {
          //   history.push("/admin/dashboard", ...res.data.username);
          // } else {
          //   history.push("/");
          // }
        } else if (res.data.status === 401) {
          swal("Warning", res.data.message, "warning");
        } else {
          setLogin({ ...loginInput, error_list: res.data.validation_errors });
        }
      });
    });
  };

  return (
    <>
      <Navbar />

      {/* <section className="sticky-top background-1  py-2 fs--1 fw-600 font-1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 align-self-center mt-4 mb-4">
              <p className="  text-white mb-md-0 font-1 text-center  ">
                V-HOSPITALITY
              </p>
            </div>
           
          </div>
        </div>
      </section> */}

      <div className="">
        <section className="py-0 font-1">
          <div className="container-fluid">
            <div className="row align-items-center text-center justify-content-center h-full">
              <div className="col-sm-6 col-md-5 col-lg-4 col-xl-3">
                <h3 className="fw-300 mb-5 display-5">Sign In </h3>
                <form onSubmit={loginSubmit}>
                  <div className="mb-3 ">
                    <input
                      className="form-control mb-3 input-lg"
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={handleInput}
                      value={loginInput.email}
                    />
                    <label className="span_label">
                      {loginInput.error_list.email}
                    </label>
                  </div>
                  <input
                    className="form-control mb-3 input-lg"
                    name="password1"
                    type="password"
                    placeholder="Password"
                    onChange={handleInput}
                    value={loginInput.password1}
                  />
                  <label className="span_label">
                    {loginInput.error_list.password1}
                  </label>
                  <div className="row align-items-center">
                    <div className="col text-left">
                      <div className="fs--1 d-inline-block">
                        <Link to="./recoverpass">Forgot your password?</Link>
                      </div>
                    </div>
                    <div className="col text-right">
                      <button
                        className="btn-block btn btn-primary"
                        type="submit"
                      >
                        Log in
                      </button>
                    </div>
                  </div>
                </form>
                <hr className="color-9 mt-6" />
                <div className="fs--1">
                  Need an account?
                  <Link to="/register"> Sign up.</Link>
                </div>
                <hr className="color-9" />{" "}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;
