import React from 'react';
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";

const Faqs = () => {
  return (
    <div>
        <h1 className="fs-6 p-4  mb-4 text-white color-vhospitalilty-b ">
              Frequenty Asked Questions (FAQ)s
            </h1>
            <MDBAccordion flush initialActive={1} className="mb-8 text-dark">
              <MDBAccordionItem
                className="text-dark"
                collapseId={1}
                headerTitle="Can I come with my pets?"
              >
                Yes, you can.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={2}
                headerTitle="Can I get a refund, in an event of emergency?"
              >
                No, please. We do not refund after payment.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={3}
                headerTitle="Can I access the place before date of booking?"
              >
                No please. We advise that you keep to the initial booked periods
                and hours.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={4}
                headerTitle="Is there any service to cater for my meals and drinks?"
              >
                Yes, we do run a top notch Canela restaurant &amp; V-Cafe to
                takes care of your meals and drinks.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={5}
                headerTitle="Does the place have a parking lot?"
              >
                Yes, we have a provision per individual.
              </MDBAccordionItem>
            </MDBAccordion>
    </div>
  )
}

export default Faqs