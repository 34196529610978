import React, { useEffect, useState } from "react";
import Navbar from "../../../layouts/frontend/Navbar";
import axios from "axios";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import Footer from "../Footer";
import {
  MDBListGroup,
  MDBListGroupItem,
  MDBRipple,
  MDBBadge,
  MDBIcon,
} from "mdb-react-ui-kit";
import img from "../../../assets/admin/assets/img/logo1.png";
import error_image from "../../../assets/admin/assets/img/error.jpg";



import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Notification() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;

    if (!localStorage.getItem("auth_token")) {
      history.push("/");
      swal("Warning", "Login to goto Cart Page", "error");
    }
    setLoading(false);

    return () => {
      isMounted = false;
    };
  }, [history]);



  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <img src={img} width="120px" />
        <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
      </div>
    );
  } else {
    var notification_HTML = "";

    notification_HTML = <div>
      Notification
    </div>
  }

  if (notification_HTML.length > 0) {
    return (
      <div>
        {notification_HTML}
      </div>
    );
  } else {
    return(
      <div>
        <div className="container text-center mt-7">
            <h4 className="display-5 mb-0">You have no notifications, yet</h4>
            <LazyLoadImage
                      alt="error_thumbnail"
                      className="mb-6"
                      effect="blur"
                      width="85%"
                      src={error_image}  />
              
              <div>
                
                <div className="row mb-8 text-center">
                  <div className="float-left">
                  <Link to="/search-homes" className="btn  btn-lg mt-3 btn-dark underlink_removal">
                              Back to Browse Property
                            </Link>
                  </div>
                </div>
              </div>
            
          </div>        
      </div>
    );
  }

  
}

export default Notification;
