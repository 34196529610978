import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import ReactLoading from 'react-loading'
import Navbar from '../../../layouts/frontend/Navbar';
import img from '../../../assets/admin/assets/img/logo1.png';
import { MDBIcon } from "mdb-react-ui-kit";
import { MDBAccordion, MDBAccordionItem} from 'mdb-react-ui-kit';
import Footer from '../Footer';
import {  MDBBadge } from "mdb-react-ui-kit";

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import vendor_image from "../../../assets/admin/assets/img/logo1.png";
import feature_bed from "../../../assets/admin/assets/img/bed.png";
import feature_bath from "../../../assets/admin/assets/img/shawer.png";
import RecommendedHomes from "../../../ReusedComponents/RecommendedHomes";
import ProductViewPagePromo from "../../../ReusedComponents/ProductViewPagePromo";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function ProductDetail(props){

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState([]);
    const [category, setCategory] = useState([]);
    const [quantity, setQuantity] = useState(1);

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };


    const photos = [
      {
        src: `${process.env.REACT_APP_LOCALHOST_APP_URL}/${product.image}`,
        width: 3,
        height: 2,
      },
      {
        src: `${process.env.REACT_APP_LOCALHOST_APP_URL}/${product.image}`,
        width: 2,
        height: 2,
      },
      {
        src: `${process.env.REACT_APP_LOCALHOST_APP_URL}/${product.image}`,
        width: 2,
        height: 2,
      },
      {
        src: `${process.env.REACT_APP_LOCALHOST_APP_URL}/${product.image}`,
        width: 4,
        height: 3,
      },
      {
        src: `${process.env.REACT_APP_LOCALHOST_APP_URL}/${product.image}`,
        width: 3,
        height: 2,
      },
    ];

    useEffect(() => {

        let isMounted = true;

        const category_slug = props.match.params.category;
        const product_slug = props.match.params.product;
        axios.get(`/api/viewproductdetail/${category_slug}/${product_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.data.status === 200)
                {
                    setProduct(res.data.product_data.product);
                    setCategory(res.data.product_data.category);
                    setLoading(false);
                }
                else if(res.data.status === 404)
                {
                    history.push('/collections');
                    swal("Warning", res.data.message, "error");
                }
            }
        });

        return () => {
            isMounted = false
        };
    }, [props.match.params.category, props.match.params.product, history]);


    // Quantity Increment/Decrement in Hooks - Start
    const handleDecrement = () => {
        if(quantity > 1){
            setQuantity(prevCount => prevCount - 1);
        }
    }
    const handleIncrement = () => {
        if(quantity < 10){
            setQuantity(prevCount => prevCount + 1);
        }
    }
    // Quantity Increment/Decrement in Hooks - End

    const submitAddtocart = (e) => {
        e.preventDefault();
        
        const data = {
            product_id: product.id,
            product_qty: quantity,
        }

        axios.post(`/api/add-to-cart`, data).then(res=>{
            if(res.data.status === 200){
                //Created - Data Inserted
                history.push("/cart");
                swal("Success",res.data.message,"success");
            }else if(res.data.status === 409){
                //Already added to cart
                history.push("/cart");
                swal("Success",res.data.message,"success");
            }else if(res.data.status === 401){
                //Unauthenticated
                swal("Error",res.data.message,"error");
                history.push('/login');
            }else if(res.data.status === 404){
                //Not Found
                swal("Warning",res.data.message,"warning");
                history.push('/login');
            }
        });

    }

    // WISHLIST
    const addToWishList = (e) => {
        e.preventDefault();
        
        const data = {
            product_id: product.id,
            product_qty: quantity,
        }

        axios.post(`/api/add-to-wishlist`, data).then(res=>{
            if(res.data.status === 200){
                //Created - Data Inserted
                history.push("/account/wishlist");
                swal("Success",res.data.message,"success");
            }else if(res.data.status === 409){
                //Already added to cart
                history.push("/account/wishlist");
                swal("Success",res.data.message,"success");
            }else if(res.data.status === 401){
                //Unauthenticated
                swal("Error",res.data.message,"error");
                history.push('/login');
            }else if(res.data.status === 404){
                //Not Found
                swal("Warning",res.data.message,"warning");
                history.push('/login');
            }
        });

    }


    if(loading)
    {
        return (
            <div className="ReactLoadingCenter" align="center">
                <img src={img}  width="120px" />
                <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
            </div>
          );
    }
    else {
      var avail_stock = "";
      if (product.status === 0) {
        avail_stock = (
          <div className="">
  
            <div class="badge badge-warning">Available</div>
            
            
            <div className="row">
              <div className="col-md-12 mt-3">
                <small>Expected No. of Occupants</small>
                <div className="input-group">
                  <button
                    type="button"
                    onClick={handleDecrement}
                    className="input-group-text"
                  >
                    -
                  </button>
                  <div className="form-control text-center">{quantity}</div>
                  <button
                    type="button"
                    onClick={handleIncrement}
                    className="input-group-text"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
  
            <div class="btn-group mb-5 mt-3">
              <button class="btn btn-dark" onClick={addToWishList}>ADD TO WISHLIST</button>
              <button class="btn color-vh-button" onClick={submitAddtocart}>BOOK PROPERTY</button>
              {/* <button  class="btn btn-icon btn-dark btn-icon-right" onClick={addToWishList}>
                  <span class="fa fa-heart">
                  </span> ADD TO WISHLIST
                </button>
              <button  class="btn btn-icon color-vh-button btn-icon-right mt-3" onClick={submitAddtocart}>
                  <span class="fa fa-property">
                  </span> BOOK PROPERTY
                </button> */}
            </div>
  
  
          </div>
        );
      } else {
        avail_stock = (
          <div>
            <div class="badge badge-warning">Not Available</div>
  
            <div className="row">
              <div class="mt-3 col-md-6">
                {/* <button class="btn btn-dark" onClick={addToWishList}>ADD TO WISHLIST</button> */}
  
                <button  class="btn btn-icon btn-dark btn-icon-right" onClick={addToWishList}>
                  <span class="fa fa-heart">
                  </span> ADD TO WISHLIST
                </button>
              </div>
            </div>
          </div>
        );
      }
    }



      return (
        <div>
          <Navbar />
          <div className="alert alert-dark" role="alert">
            <div className="container">
              <h6 className="">
                <Link className="underlink_removal" to={`/search-homes`}>
                  Search Homes
                </Link>{" "}
                <span class='icon-Arrow-Right2'></span> {product.name}
              </h6>
            </div>
          </div>
    
          <div className="py-3">
            <div className="container">
              <div className="row">
                <div className="cpl-lg-8 col-lg-4">
                  <div className="">
                    <div class="col-lg-12 mt-5 mt-lg-0">
                      <div className="row mb-4 mt-0 form-group">
                        <h1 className="fs-6 p-4 mb-2 text-white background-3">
                          About The Dealer
                        </h1>
                        <div className="col-lg-4 ">
                          <a
                            href="assets/images/portraits/square/03.jpg"
                            data-lightbox="profile-pic"
                          >
                            <LazyLoadImage
                              alt="error_thumbnail"
                              className="img-thumbnail radius-primary"
                              effect="blur"
                              width="100%"
                              src={vendor_image}
                            />
                          </a>
                        </div>
    
                        <div className="col-lg-8 text-lg-left">
                          <h3
                            class="fs-5 color-black mb-2  mt-2 font-1 "
                            style={{ "text-transform": "uppercase" }}
                          >
                            <strong>{product.brand}</strong>
                          </h3>
                          <strong class="mb-0 mt-3 color-3 font-1">
                            {product.telephone}{" "}
                          </strong>{" "}
                          <br />
                          <small class="mb-0 mt-3 color-3 font-1">
                            {product.vendor_email}{" "}
                          </small>{" "}
                          <br />
                          {/* <small class="mb-0 color-7">5 Property Booked</small> */}
                          <hr class="color-9" />
                          <div class="fs--1 fw-600"></div>
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <h1 className="fs-6 p-4 mb-2 text-white background-3 ">
                    About this Product
                  </h1>
    
                  <div class="color-vhospitalilty mb-2">
                    <span class="fa fa-star mr-1"></span>
                    <span class="fa fa-star mr-1"></span>
                    <span class="fa fa-star mr-1"></span>
                    <span class="fa fa-star-half-o mr-1"></span>
                    <span class="fa fa-star-o mr-1"></span>
                    <a class="fs--1" href="#">
                      {" "}
                      118 customer reviews
                    </a>
                  </div>
                  <h1 class=" fw-600" style={{ "text-transform": "uppercase" }}>
                    {product.name}{" "}
                  </h1>
                  <div class="mt-3">
                    <span class="fs-2 font-1">NGN: {product.selling_price}</span>
                    <strong>
                      <s className="ms-2 text-secondary font-1">
                        {" "}
                        NGN: {product.original_price}{" "}
                      </s>
                    </strong>
                  </div>
                  {/* <div class="badge badge-warning">{avail_stock}</div> */}
    
                  <div>{avail_stock}</div>
                  {/* <h1 class="fs-2">
                    <button
                      type="button"
                      className="btn color-vh-button mt-3 w-100"
                      onClick={addToWishList}
                    >
                      Add to Wishlist
                    </button>
                  </h1> */}
                </div>
    
                <div className="col-lg-4 col-lg-8 ">
                  {/* IMAGE GALLERY SECTION */}
                  <div className="mb-6">
                    <Gallery
                      loading="lazy"
                      photos={photos}
                      onClick={openLightbox}
                    />
                    <ModalGateway>
                      {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                          <Carousel
                            currentIndex={currentImage}
                            views={photos.map((x) => ({
                              ...x,
                              srcset: x.srcSet,
                              caption: x.title,
                            }))}
                          />
                        </Modal>
                      ) : null}
                    </ModalGateway>
                  </div>
                </div>
    
                <div className="row">
                  <div className="col-12">
                    {/* PROPERTY DESCRIPTION */}
                    <div class="mt-2">
                      {/* <h1 className="fs-6 p-4 mt-6 mb-2 text-white color-vhospitalilty-b ">
                      About this Product
                    </h1> */}
    
                      
                      <h3
                        class=" fw-400 color-vhospitalilty"
                        style={{ "text-transform": "uppercase" }}
                      >
                        <span class="icon-Home fs-4 "></span> <strong>Property Description</strong>
                      </h3>
                      <hr class="short left my-3 color-1" align="left" />
    
                      <span
                        className="font-1 dropcap lead"
                        style={{ "text-align": "justify" }}
                      >
                        {product.description}
                      </span>
                    </div>
    
                    {/* ADDITIONAL DETAILS */}
                    <div>
                      <h3
                        class=" fw-400 color-vhospitalilty"
                        style={{ "text-transform": "uppercase" }}
                      >
                      <span class="icon-Check fs-4 mt-4"></span> <strong>Additional Details</strong>
                      </h3>
                      <hr class="short left my-3 color-1" align="left" />
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div class="mt-3">
                          <div
                            className="row "
                            style={{ "text-transform": "uppercase" }}
                          >
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-white color-vhospitalilty-b ">
                                SWIMMING POOL
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-dark background-10 ">
                                {product.swimmingpool === 0 ? "YES" : "NO"}
                              </h5>
                            </div>
                          </div>
                          <div
                            className="row "
                            style={{ "text-transform": "uppercase" }}
                          >
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-white color-vhospitalilty-b ">
                                Free parking
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-dark background-10 ">
                                {product.freeparking === 0 ? "YES" : "NO"}
                              </h5>
                            </div>
                          </div>
                          <div
                            className="row "
                            style={{ "text-transform": "uppercase" }}
                          >
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-white color-vhospitalilty-b ">
                                Room service
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-dark background-10 ">
                                {product.roomservice === 0 ? "YES" : "NO"}
                              </h5>
                            </div>
                          </div>
                          <div
                            className="row "
                            style={{ "text-transform": "uppercase" }}
                          >
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-white color-vhospitalilty-b ">
                                On-site restaurant
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-dark background-10 ">
                                {product.restuarant === 0 ? "YES" : "NO"}
                              </h5>
                            </div>
                          </div>
                          <div
                            className="row "
                            style={{ "text-transform": "uppercase" }}
                          >
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-white color-vhospitalilty-b ">
                                Non-smoking rooms
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-dark background-10 ">
                                {product.nosmoke === 0 ? "YES" : "NO"}
                              </h5>
                            </div>
                          </div>
                          <div
                            className="row "
                            style={{ "text-transform": "uppercase" }}
                          >
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-white color-vhospitalilty-b ">
                                24 Hour Front Desk
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-dark background-10 ">
                                {product.frdesk === 0 ? "YES" : "NO"}
                              </h5>
                            </div>
                          </div>
                          <div
                            className="row "
                            style={{ "text-transform": "uppercase" }}
                          >
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-white color-vhospitalilty-b ">
                                Laundry
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-dark background-10 ">
                                {product.laundry === 0 ? "YES" : "NO"}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="mt-3">
                          <div
                            className="row "
                            style={{ "text-transform": "uppercase" }}
                          >
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-white color-vhospitalilty-b ">
                                SWIMMING POOL
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-dark background-10 ">
                                {product.swimmingpool === 0 ? "YES" : "NO"}
                              </h5>
                            </div>
                          </div>
                          <div
                            className="row "
                            style={{ "text-transform": "uppercase" }}
                          >
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-white color-vhospitalilty-b ">
                                Free parking
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-dark background-10 ">
                                {product.freeparking === 0 ? "YES" : "NO"}
                              </h5>
                            </div>
                          </div>
                          <div
                            className="row "
                            style={{ "text-transform": "uppercase" }}
                          >
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-white color-vhospitalilty-b ">
                                Room service
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-dark background-10 ">
                                {product.roomservice === 0 ? "YES" : "NO"}
                              </h5>
                            </div>
                          </div>
                          <div
                            className="row "
                            style={{ "text-transform": "uppercase" }}
                          >
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-white color-vhospitalilty-b ">
                                On-site restaurant
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-dark background-10 ">
                                {product.restuarant === 0 ? "YES" : "NO"}
                              </h5>
                            </div>
                          </div>
                          <div
                            className="row "
                            style={{ "text-transform": "uppercase" }}
                          >
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-white color-vhospitalilty-b ">
                                Non-smoking rooms
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-dark background-10 ">
                                {product.nosmoke === 0 ? "YES" : "NO"}
                              </h5>
                            </div>
                          </div>
                          <div
                            className="row "
                            style={{ "text-transform": "uppercase" }}
                          >
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-white color-vhospitalilty-b ">
                                24 Hour Front Desk
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-dark background-10 ">
                                {product.frdesk === 0 ? "YES" : "NO"}
                              </h5>
                            </div>
                          </div>
                          <div
                            className="row "
                            style={{ "text-transform": "uppercase" }}
                          >
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-white color-vhospitalilty-b ">
                                Laundry
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5 className="fs-6 p-2 mt-0 mb-2 text-dark background-10 ">
                                {product.laundry === 0 ? "YES" : "NO"}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
    
                    {/* FEATURES */}
                    <div class="mt-5">
                      
                      <h3
                        class=" fw-400 color-vhospitalilty"
                        style={{ "text-transform": "uppercase" }}
                      >
                        <span class="icon-Leafs fs-4 "></span> <strong>Features</strong>
                      </h3>
                      <hr class="short left my-3 color-1" align="left" />
    
                      <div className="row">
                        <div className="col-md-3">
                          <img src={feature_bed} width="30px" alt="product" />
                          <h5 className="font-1">
                            BEDROOMS{" "}
                            <MDBBadge className="ms-0 color-vh-button">
                              {" "}
                              {product.beds}
                            </MDBBadge>{" "}
                          </h5>
                          {/* <h5 className="font-1">{product.beds}</h5> */}
                          {/* BEDROOMS {product.beds}{" "} */}
                        </div>
                        <div className="col-md-3">
                          <img src={feature_bath} width="30px" alt="product" />
                          <h5 className="font-1">
                            BATHROOMS{" "}
                            <MDBBadge className="ms-0 color-vh-button">
                              {" "}
                              {product.baths}
                            </MDBBadge>{" "}
                          </h5>
                        </div>
                      </div>
                    </div>
    
                    {/* VIRTUAL TOUR */}
    
                    <div class="mt-5">
                      
                      <h3
                        class=" fw-400  color-vhospitalilty"
                        style={{ "text-transform": "uppercase" }}
                      >
                        <span class="icon-Video-4 fs-4 "></span> <strong>Virtual Tour</strong>
                      </h3>
                      <hr class="short left my-3 color-1" align="left" />
    
                      <div
                        style={{
                          position: "relative",
                          height: "0",
                          "padding-top": "100%",
                        }}
                      >
                        <iframe
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                          }}
                          src="https://virtrav.com/tour/vanernresidence/"
                          name="Vanern Residence"
                          width="100%"
                          height="100%"
                          frameborder="0"
                          allowfullscreen="true"
                          allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay; camera; microphone"
                        ></iframe>
                      </div>
    
                      {/* <section>
                      <div
                        class="background-holder"
                        style={{
                          "background-image":
                            "url(http://img.youtube.com/vi/L3V7LKYPIUQ/maxresdefault.jpg);",
                        }}
                      >
                        <div
                          class="youtube-background"
                          data-video-url="http://youtu.be/L3V7LKYPIUQ"
                          data-start-at="0"
                          data-stop-at="444"
                        ></div>
                      </div>
                      <div class="text-center" style={{ cursor: "pointer" }}>
                        <img
                          src={
                            require(`../../../assets/admin/assets/img/icon-play-o.svg`)
                              .default
                          }
                          width="80"
                        />
                      </div>
                    </section> */}
                    </div>
    
                    {/* VENDOR'S INFORMATION */}
                    <div class="mt-5">
                      
                      <h3
                        class=" fw-400 color-vhospitalilty"
                        style={{ "text-transform": "uppercase" }}
                      >
                        <span class="icon-Checked-User fs-2 "></span> <strong>Vendor's Information</strong>
                      </h3>
                      <hr class="short left my-3 color-1" align="left" />
    
                      <hr class="color-9" />
                      {/* <p class="color-success fw-600 mb-0">In Stock.</p>
                  <h6 class="color-5 mb-3">SKU: A045-1041-00</h6> */}
                      <span
                        className="fs--4 color-1 font-1 dropcap lead"
                        style={{ "text-align": "justify" }}
                      >
                        Our time for <strong>Check-in</strong> and{" "}
                        <strong>Check-out</strong> are <strong>2pm</strong> and{" "}
                        <strong>12 noon</strong> respectively. Kindly note that we
                        do accept online payments, however for security reasons
                        proof of payments must be presented upon arrival. All
                        payments are made to <strong>V-Hospitality</strong> and not
                        through any third party.{" "}
                      </span>
                    </div>
    
                    {/* <h1 className="fs-6 p-4 mt-6 mb-2 text-white color-vhospitalilty-b ">
                    Frequenty Asked Questions (FAQ)s
                  </h1> */}
                    <h3
                      class=" fw-400 color-vhospitalilty mt-6"
                      style={{ "text-transform": "uppercase" }}
                    >
                      <strong>Frequenty Asked Questions (FAQ)s</strong>
                    </h3>
                    <hr class="short left my-3 color-1" align="left" />
                    <MDBAccordion flush initialActive={1} className="mb-5  lead">
                      <MDBAccordionItem
                        className="text-dark"
                        collapseId={1}
                        headerTitle="Can I come with my pets?"
                      >
                        Yes, you can.
                      </MDBAccordionItem>
                      <MDBAccordionItem
                        collapseId={2}
                        headerTitle="Can I get a refund, in an event of emergency?"
                      >
                        No, please. We do not refund after payment.
                      </MDBAccordionItem>
                      <MDBAccordionItem
                        collapseId={3}
                        headerTitle="Can I access the place before date of booking?"
                      >
                        No please. We advise that you keep to the initial booked
                        periods and hours.
                      </MDBAccordionItem>
                      <MDBAccordionItem
                        collapseId={4}
                        headerTitle="Is there any service to cater for my meals and drinks?"
                      >
                        Yes, we do run a top notch Canela restaurant &amp; V-Cafe to
                        takes care of your meals and drinks.
                      </MDBAccordionItem>
                      <MDBAccordionItem
                        collapseId={5}
                        headerTitle="Does the place have a parking lot?"
                      >
                        Yes, we have a provision per individual.
                      </MDBAccordionItem>
                    </MDBAccordion>
    
    
                    {/* <RecommendedHomes /> */}
                    <ProductViewPagePromo />
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          {/* FOOTER */}
          <Footer></Footer>
        </div>
      );
}

export default ProductDetail