import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactLoading from "react-loading";
import { useHistory, Link } from "react-router-dom";
import swal from "sweetalert";
import Navbar from "../../../layouts/frontend/Navbar";
import img from "../../../assets/admin/assets/img/logo1.png";
import { MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import Footer from "../Footer";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import error_image from "../../../assets/admin/assets/img/error.jpg";
import ProductViewPagePromo from "../../../ReusedComponents/ProductViewPagePromo";



function ViewProduct(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);

  const [q, setQ] = useState("");
  const [searchParam] = useState(["slug", "name", "location"]);
  const [filterParam, setFilterParam] = useState(["All"]);

  useEffect(() => {
    let isMounted = true;

    const product_slug = props.match.params.slug;
    axios.get(`/api/fetchproducts/${product_slug}`).then((res) => {
      if (isMounted) {
        if (res.data.status === 200) {
          setProduct(res.data.product_data.product);
          setCategory(res.data.product_data.category);
          setLoading(false);
        } else if (res.data.status === 400) {
          swal("Warning", res.data.message, "");
        } else if (res.data.status === 404) {
          swal("Warning", res.data.message, "error");
          history("/collections");
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, [props.match.params.slug, history]);

  const data = Object.values(product);

  function search(items) {
    return items.filter((item) => {
      if (filterParam == "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <img src={img} width="120px" />
        <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
      </div>
    );
  } else {
    var showProductList = "";
    // showProductList = product.map((item, idx) => {
    showProductList = search(data).map((item, idx) => {
      return (
        <div
          className="col-md-4 col-6 col-md-4 col-sm-6 mb-4 align-self-stretch"
          key={idx}
        >
          <Link
            className="color-1"
            to={`/collections/${item.category.slug}/${item.id}`}
          >
            <div class="card radius-square">
              <LazyLoadImage
                alt={item.name}
                effect="blur"
                src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${item.image}`}
              />
              <div class="card-block p-2">
                <div class="row align-items-center mt-3">
                  <div class="col-12">
                  {item.status === 0 ? (
                  <div class="badge badge-warning float-end">Available</div>
                ) : (
                  <div class="badge badge-warning float-end">Not Available</div>
                )}

                  
                  <p
                        className="fs--4 color-1 font-1"
                        style={{ "text-transform": "uppercase" }}
                      >
                        <strong>{item.name}</strong>
                      </p>

                  </div>
                  <div class="col">
                    <h5 class="fs-3 d-inline-block mb-0 color-vhospitalilty ">
                    NGN {item.selling_price}{" "}<span className="color-5 fs--1 fw-400">/ night]</span>
                    </h5>

                  </div>
                </div>
                <div class="row my-2">
                  <div class="col-12">
                    <div class="d-inline-block color-vhospitalilty">
                      <span class="fa fa-star mr-1"></span>
                      <span class="fa fa-star mr-1"></span>
                      <span class="fa fa-star mr-1"></span>
                      <span class="fa fa-star mr-1"></span>
                      <span class="fa fa-star mr-1"></span>
                    </div>
                    <div class="d-inline-block fs--1">
                        {item.category.name}
                    </div>
                    <div class="color-5 mt-2">
                      <Link class="color-5 fs--1" to="#">
                        <span class="fa fa-heart mr-1"></span>Add to Wish
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

            
            </div>

            {/* <div className="card radius-square">
            <LazyLoadImage
              alt={item.name}
              effect="blur"
              src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${item.image}`}  />

                {item.status === 0 ? (
                  <span class="badge rounded-pill badge-notification bg-danger home font-1">
                    AVAILABLE
                  </span>
                ) : (
                  <span class="badge rounded-pill badge-notification bg-danger home font-1">
                    BOOKED
                  </span>
                )}

                <div className="card-block p-2">
                  <small className="color-1 underlink_removal font-1">
                    {item.category.name}
                  </small>
                  <div className="row align-items-center mt-3 ">
                    <div className="col-12 float-end">
                      <p
                        className="fs--4 color-1 font-1"
                        style={{ "text-transform": "uppercase" }}
                      >
                        <strong>{item.name}</strong>
                      </p>
                    </div>
                    <div className="col float-end ">
                      <p className="lead color-vhospitalilty fw-600 font-1">
                        NGN {item.selling_price}
                        <span className="color-5 fs--1 fw-400">/ night]</span>
                        <button
                          className="fa fa-heart mr-1 fs-4 float-end"
                          onClick={""}
                        ></button>
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
          </Link>
        </div>
      );
    });
  }

  if (showProductList.length > 0) {
    return (
      <div>
        <Navbar />

        <section className="p-0 color-white font-1">
          <div className="background-holder overlay overlay-1">
            {/* className="background-holder overlay overlay-gradient"> */}{" "}
          </div>
          <div className="container">
            <div className="row h-half align-items-center py-5 text-center justify-content-center">
              <div className="container mt-6 px-8">
                <h1 className=" fs-md-4 mb-3 color-vhospitalilty">
                  <strong>{category.name} Collections</strong>
                </h1>
              </div>
            </div>
          </div>
        </section>
        <div className="alert alert-dark " role="alert">
          <Link to={`/collections`} className="underlink_removal">
            Collections
          </Link>{" "}
          <span class="icon-Arrow-Right2"></span> {category.slug}
        </div>

        <div className="container mt-6 px-8">
          <div className="row">
            <div className="col-12">
              <form className="d-flex input-group w-auto">
                <input
                  type="search"
                  className="form-control input-lg input_border"
                  placeholder="Search by brand, name, or location"
                  aria-label="Search"
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
                <span class="input-group-btn">
                  <button class="btn color-vhospitalilty-b" type="button">
                    <span class="icon-Magnifi-Glass2 fs-4"></span>
                  </button>
                </span>
              </form>
            </div>
          </div>
        </div>

        <div className="py-3 mb-6 mt-6">
          <div className="container">
            <div className="row mb-2">{showProductList}</div>
          </div>
        </div>
        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />

        <section className="p-0 color-white font-1">
          <div className="background-holder overlay overlay-1">
            {/* className="background-holder overlay overlay-gradient"> */}{" "}
          </div>
          <div className="container">
            <div className="row h-half align-items-center py-5 text-center justify-content-center">
              <div className="container mt-6 px-8">
                <h1 className=" fs-md-4 mb-3 color-vhospitalilty">
                  <strong>{category.name} Collections</strong>
                </h1>
              </div>
            </div>
          </div>
        </section>
        <div className="alert alert-dark " role="alert">
          <Link to={`/collections`} className="underlink_removal">
            Collections
          </Link>{" "}
          <span class="icon-Arrow-Right2"></span> {category.slug}
        </div>

        <div className="container mt-6 px-8">
          <div className="row">
            <div className="col-12">
              <form className="d-flex input-group w-auto">
                <input
                  type="search"
                  className="form-control input-lg input_border"
                  placeholder="Search by brand, name, or location"
                  aria-label="Search"
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
                <span class="input-group-btn">
                  <button class="btn color-vhospitalilty-b" type="button">
                    <span class="icon-Magnifi-Glass2 fs-4"></span>
                  </button>
                </span>
              </form>
            </div>
          </div>
        </div>

        <div className="py-3 px-3 mt-6">
          <div className="container text-center ">
            <h4 className="display-5 mb-0">
              Sorry, Property not found under {category.slug}
            </h4>
            <LazyLoadImage
              alt="error_thumbnail"
              className="mb-0"
              effect="blur"
              width="65%"
              src={error_image}
            />
            {/* <div className="row mb-2">{showProductList}</div> */}
          </div>
        </div>
            <ProductViewPagePromo />
        {/* <Footer /> */}
        <Footer></Footer>
      </div>
    );
  }
}

export default ViewProduct;
