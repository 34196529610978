import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import axios from "axios";
import "../../App.css";
// import "../../assets/admin/css/posh_css.css";
import Parser from "html-react-parser";

import { MDBBadge, MDBBtn } from "mdb-react-ui-kit";
import Footer from "./Footer";
import Navbar from "../../layouts/frontend/Navbar";
import swal from "sweetalert";
import img from "../../assets/admin/assets/img/logo1.png";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import RecommendedHomes from "../../ReusedComponents/RecommendedHomes";

import error_image from "../../assets/admin/assets/img/error.jpg";

const Home = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [viewProduct, setProduct] = useState([]);
  const [quantity, setQuantity] = useState(1);

  const [q, setQ] = useState("");
  const [searchParam] = useState(["brand", "name", "location"]);
  const [filterParam, setFilterParam] = useState(["All"]);

  useEffect(() => {
    document.title = "Home";
    axios.get("/api/view-homes").then((res) => {
      if (res.data.status === 200) {
        setProduct(res.data.houses);
        setLoading(false);
      }
    });
  }, []);

  const data = Object.values(viewProduct);

  function search(items) {
    return items.filter((item) => {
      if (item.brand == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.location == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.name == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  // WISHLIST
  const addToWishList = (e) => {
    e.preventDefault();

    const data = {
      product_id: viewProduct.id,
      product_qty: quantity,
    };

    axios.post(`/api/add-to-wishlist`, data).then((res) => {
      if (res.data.status === 200) {
        //Created - Data Inserted
        history.push("/account/wishlist");
        swal("Success", res.data.message, "success");
      } else if (res.data.status === 409) {
        //Already added to cart
        history.push("/account/wishlist");
        swal("Success", res.data.message, "success");
      } else if (res.data.status === 401) {
        //Unauthenticated
        swal("Error", res.data.message, "error");
      } else if (res.data.status === 404) {
        //Not Found
        swal("Warning", res.data.message, "warning");
      }
    });
  };

  var loadingTag = `<img src=${img} width="120px" />
  <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
`;

  var display_Productdata = "";
  if (loading) {
    return (
      // <div className="ReactLoadingCenter" align="center">{Parser(loadingTag)}</div>
      <div className="ReactLoadingCenter" align="center">
        <img src={img} width="120px" />
        <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
      </div>
    );
  } else {
    display_Productdata = search(data).map((item, idx) => {
      return (
        <>
          <div
            className="col-md-4 col-6 col-md-4 col-sm-6 mb-4 align-self-stretch"
            key={idx}
          >
            {/* <i class="flag flag-uae"></i> */}
            <Link
              className="color-1 underlink_removal"
              to={`/home/${item.id}`}
              style={{ "text-transform": "uppercase" }}
            >
              <div className="card radius-square">
                <LazyLoadImage
                  alt={item.name}
                  effect="blur"
                  src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${item.image}`}
                />
              <div class="card-block p-2">
                <div class="row align-items-center mt-3">
                  <div class="col-12">
                  {item.status === 0 ? (
                  <div class="badge badge-warning float-end">Available</div>
                ) : (
                  <div class="badge badge-warning float-end">Not Available</div>
                )}

                  
                  <p
                        className="fs--4 color-1 font-1"
                        style={{ "text-transform": "uppercase" }}
                      >
                        <strong>{item.name}</strong>
                      </p>

                  </div>
                  <div class="col">
                    <h5 class="fs-3 d-inline-block mb-0 color-vhospitalilty ">
                    NGN {item.selling_price}{" "}<span className="color-5 fs--1 fw-400">/ night]</span>
                    </h5>

                  </div>
                </div>
                <div class="row my-2">
                  <div class="col-12">
                    <div class="d-inline-block color-vhospitalilty">
                      <span class="fa fa-star mr-1"></span>
                      <span class="fa fa-star mr-1"></span>
                      <span class="fa fa-star mr-1"></span>
                      <span class="fa fa-star mr-1"></span>
                      <span class="fa fa-star mr-1"></span>
                    </div>
                    <div class="d-inline-block fs--1">
                        {item.category.name}
                    </div>
                    <div class="color-5 mt-2">
                      <button type="button" class="btn btn-light color-5 fs--1" onClick={addToWishList}>
                        <span class="fa fa-heart mr-1"></span>Add to Wish
                      </button>
                    </div>
                  </div>
                </div>
              </div>

                
              </div>
            </Link>
          </div>
        </>
      );
    });
    // viewProduct.map((item, idx) => { });
  }

  if (display_Productdata.length > 0) {
    return (
      <>
        {/* NAVBAR -------------------------- */}
        <Navbar />

        <section className="p-0 color-white font-1">
          <div className="background-holder overlay overlay-1">
            {/* className="background-holder overlay overlay-gradient"> */}{" "}
          </div>
          <div className="container">
            <div className="row h-half align-items-center py-5 text-center justify-content-center">
              <div className="container mt-6 px-8">
              <h1 className=" fs-md-4 mb-3 color-vhospitalilty">
                <strong>SEARCH PROPERTY</strong>
              </h1>
                {/* <p className="lead fs-3"><strong>SEARCH PROPERTIES</strong></p> */}
                {/* <div className="row">
            <div className="col-12">
              <form className="d-flex input-group w-auto">
                <input
                  type="search"
                  className="form-control input-lg input_border"
                  placeholder="Search by brand, name, or location"
                  aria-label="Search"
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
                <MDBBtn className="color-vhospitalilty-b">Search</MDBBtn>
              </form>
            </div>
          </div> */}
              </div>
            </div>
          </div>
        </section>

        <section className="font-1 py-5">
          <div className="row pr-4 pl-4 pb-4">
            <div className="col-12">
              <form>
                <div class="input-group">
                  <input
                    type="search"
                    className="form-control input-lg input_border"
                    placeholder="Search by brand, name, or location"
                    aria-label="Search"
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                  />
                  <span class="input-group-btn">
                    <button class="btn color-vhospitalilty-b" type="button">
                    <span class='icon-Magnifi-Glass2 fs-4'></span>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>

          <div className="container">
            <div className="row">
              {/* BEGINING OF FILTER */}

              <div className="col-2 hidden-md-down">
                <small className="mt-4">
                  <span className="mr-1 fs-4 fa fa-home color-vhospitalilty mr-1"></span>{" "}
                  Property Type{" "}
                </small>
                
                <form className="d-inline-block form-inline mb-4">
                  <div className="form-group mr-3">
                    <select
                      name="houseType"
                      className="form-control d-inline-block ml-2"
                      style={{ width: "200px", height: "auto" }}
                      onChange={(e) => {
                        setFilterParam(e.target.value);
                      }}
                      // onChange={this.props.change}
                    >
                      <option value="All">All Types</option>
                      <option value="Varnern Residence">
                        Varnern Residence
                      </option>
                      <option value="V-Hotel">V-Hotel</option>
                      <option value="V-Apartments">V-Apartments</option>
                      <option value="V-Homes">V-Homes</option>
                    </select>
                  </div>
                </form>

                <small className="mt-3 ">
                  <span className="mr-1 fs-4 fa fa-location-arrow color-vhospitalilty mr-1"></span>{" "}
                  Locations in Abuja{" "}
                </small>
                
                <form className="d-inline-block form-inline mb-4">
                  <div className="form-group mr-3">
                    <select
                      name="neighborhood"
                      className="form-control d-inline-block ml-2"
                      style={{ width: "200px", height: "auto" }}
                      onChange={(e) => {
                        setFilterParam(e.target.value);
                      }}
                    >
                      <option value="All">All Locations</option>
                      <option value="Maitama">Maitama</option>
                      <option value="Abuja">FCT</option>
                      <option value="Jabi">Jabi</option>
                      <option value="Katampe">Katampe</option>
                      <option value="Wuse">Wuse</option>
                    </select>
                  </div>
                </form>

                <small className="mt-4">
                  <span className="mr-1 fs-4 fa fa-home color-vhospitalilty mr-1"></span>{" "}
                  Type of place{" "}
                </small>
                
                <form className="d-inline-block form-inline mb-4">
                  <div className="form-group mr-3">
                    <select
                      onChange={(e) => {
                        setFilterParam(e.target.value);
                      }}
                      className="form-control d-inline-block ml-2"
                      style={{ width: "200px", height: "auto" }}
                    >
                      <option value="All">Filter By House</option>
                      <option value="Penthouse 1">Penthouse 1</option>
                      <option value="Penthouse 2">Penthouse 2</option>
                      <option value="Premium Suite">Premium Suite</option>
                      <option value="V Premium">V Premium</option>
                    </select>
                  </div>
                </form>

                <small className="mt-4">
                  <span className="mr-1 fs-4 fa fa-bathtub color-vhospitalilty mr-1"></span>{" "}
                  No. of Bathroom
                </small>
                
                <form className="d-inline-block form-inline mb-4">
                  <div className="form-group mr-3">
                    <select
                      name="baths"
                      className="form-control d-inline-block ml-2"
                      style={{ width: "200px", height: "auto" }}
                      // onChange={this.props.change}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">More than 5</option>
                    </select>
                  </div>
                </form>

                <small className="mt-4">
                  <span className="mr-1 fs-4 fa fa-bed color-vhospitalilty mr-1"></span>{" "}
                  No. of Beds
                </small>
                
                <form className="d-inline-block form-inline mb-4">
                  <div className="form-group mr-3">
                    <select
                      name="bedrooms"
                      className="form-control d-inline-block ml-2"
                      style={{ width: "200px", height: "auto" }}
                      // onChange={this.props.change}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">More than 5</option>
                    </select>
                  </div>
                </form>

                <small className="mt-4">
                  <span className="mr-1 fs-4 fa fa-check-circle color-vhospitalilty mr-1"></span>{" "}
                  Facilities &amp; Extras
                </small>
                
                <div className="form-check mb-4">
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="pool"
                      value="pool"
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Swimming Pool
                  </label>{" "}
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="two_car_garage"
                      value="two_car_garage"
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Garage{" "}
                  </label>
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="finished_basement"
                      value="finished_basement"
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Finished Basement{" "}
                  </label>{" "}
                  {/* TO BE ADDED ------------------------------------------- */}
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="wifi"
                      value="wifi"
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Free WiFi{" "}
                  </label>{" "}
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="Washer"
                      value="Washer"
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Washer{" "}
                  </label>{" "}
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="Restaurant "
                      value="Restaurant "
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Restaurant{" "}
                  </label>{" "}
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="RoomService "
                      value="RoomService "
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Room Service{" "}
                  </label>{" "}
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="kitchen "
                      value="kitchen "
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Dedicated Kitchen{" "}
                  </label>{" "}
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="RoomService "
                      value="RoomService "
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    24 Hour Front Desk{" "}
                  </label>{" "}
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="FacilitiesForDisabledGuest "
                      value="FacilitiesForDisabledGuest "
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Facilities For Disabled Guest{" "}
                  </label>{" "}
                  <br />
                </div>

                <small className="mt-3"> Avg. Customer Review</small>
                
              </div>

              {/* END OF FILTER */}

              <div className="col-lg-10 pl-lg-5">
                <div className="row pb-3 align-items-center">
                  <div className="col">
                    <p className="mb-0">
                      {" "}
                      1- {viewProduct.length} of {viewProduct.length} results
                      for
                      <strong>
                        {" "}
                        PROPERTY FOR :
                        <span className="color-vhospitalilty">
                          {" "}
                          <Link to={`/test2`}>RENT</Link>
                        </span>
                      </strong>
                    </p>
                  </div>
                  <div className="col-md-5 text-md-right">
                    <label for="exampleInputName">Sort by</label>
                    <form className="d-inline-block form-inline">
                      <div className="form-group mr-3">
                        <select
                          onChange={(e) => {
                            setFilterParam(e.target.value);
                          }}
                          className="form-control d-inline-block ml-2"
                          style={{ width: "200px", height: "auto" }}
                        >
                          <option value="All">All Types</option>
                        <option value="Varnern Residence">
                          Varnern Residence
                        </option>
                        <option value="V-Hotel">V-Hotel</option>
                        <option value="V-Apartments">V-Apartments</option>
                        <option value="V-Homes">V-Homes</option>
                        </select>
                      </div>
                    </form>
                    {/* <a className="color-5 mr-1" href="shop-products-list.html">
                                          <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNHB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxOSAxNCIgd2lkdGg9IjE5cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZXNjLz48ZGVmcy8+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSI+PGcgZmlsbD0iIzAwMDAwMCIgaWQ9IkNvcmUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04Ny4wMDAwMDAsIC01MDkuMDAwMDAwKSI+PGcgaWQ9InZpZXctbGlzdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODcuNTAwMDAwLCA1MDkuMDAwMDAwKSI+PHBhdGggZD0iTTAsOSBMNCw5IEw0LDUgTDAsNSBMMCw5IEwwLDkgWiBNMCwxNCBMNCwxNCBMNCwxMCBMMCwxMCBMMCwxNCBMMCwxNCBaIE0wLDQgTDQsNCBMNCwwIEwwLDAgTDAsNCBMMCw0IFogTTUsOSBMMTcsOSBMMTcsNSBMNSw1IEw1LDkgTDUsOSBaIE01LDE0IEwxNywxNCBMMTcsMTAgTDUsMTAgTDUsMTQgTDUsMTQgWiBNNSwwIEw1LDQgTDE3LDQgTDE3LDAgTDUsMCBMNSwwIFoiIGlkPSJTaGFwZSIvPjwvZz48L2c+PC9nPjwvc3ZnPg=="
                                              height="15"/>
                                      </a>
                                      <a className="color-5" href="shop-products-grid.html">
                                          <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik0wLDQzMiBMNyw0MzIgTDcsNDM5IEwwLDQzOSBaIE05LDQzMiBMMTYsNDMyIEwxNiw0MzkgTDksNDM5IFogTTAsNDQxIEw3LDQ0MSBMNyw0NDggTDAsNDQ4IFogTTksNDQxIEwxNiw0NDEgTDE2LDQ0OCBMOSw0NDggWiBNOSw0NDEiIGlkPSJSZWN0YW5nbGUgMTg0Ii8+PC9nPjwvZz48L3N2Zz4="
                                              height="15"/>
                                      </a> */}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <hr className="my-0 color-danger" />
                    <hr className="my-0 color-danger" />{" "}
                  </div>
                  {display_Productdata}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <RecommendedHomes /> */}
        {/* FOOTER */}
        <Footer></Footer>
      </>
    );
  } else {
    return (
      <>
        {/* NAVBAR -------------------------- */}
        <Navbar />

        <section className="p-0 color-white font-1">
          <div className="background-holder overlay overlay-gradient">
            {/* className="background-holder overlay overlay-gradient"> */}{" "}
          </div>
          <div className="container">
            <div className="row h-half align-items-center py-5 text-center justify-content-center">
              <div className="container mt-6 px-8">
              <h1 className=" fs-md-4 mb-3 color-vhospitalilty">
                <strong>SEARCH PROPERTY</strong>
              </h1>
                {/* <p className="lead fs-3"><strong>SEARCH PROPERTIES</strong></p> */}
              </div>
            </div>
          </div>
        </section>

        <section className="font-1 py-5">
          <div className="row pr-4 pl-4 pb-4">
            <div className="col-12">
              <form>
                <div class="input-group">
                  <input
                    type="search"
                    className="form-control input-lg input_border"
                    placeholder="Search by brand, name, or location"
                    aria-label="Search"
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                  />
                  <span class="input-group-btn">
                    <button class="btn color-vhospitalilty-b" type="button">
                    <span class='icon-Magnifi-Glass2 fs-4'></span>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>

          <div className="container text-center">
            <h4 className="display-3 mb-0">Sorry, No Property found</h4>
            <LazyLoadImage
              alt="error_thumbnail"
              effect="blur"
              width="65%"
              src={error_image}
            />

            {/* <img className="text-center " loading="lazy" 
                      width="85%" src={require('../../assets/admin/assets/img/error.jpg').default} /> */}
          </div>
        </section>
        {/* FOOTER */}
        <Footer></Footer>
      </>
    );
  }
};

export default Home;
