import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import Navbar from "../../layouts/frontend/Navbar";
import Footer from "./Footer";
import img from "../../assets/admin/assets/img/logo1.png";
import { MDBIcon } from "mdb-react-ui-kit";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import error_image from "../../assets/admin/assets/img/error.jpg";

function Cart() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]);
  const [allINI, setAllIni] = useState([]);
  var totalCartPrice = 0;
  var ini = 0;
  var final = 0;

  useEffect(() => {
    let isMounted = true;

    if (!localStorage.getItem("auth_token")) {
      history.push("/");
      swal("Warning", "Login to goto Cart Page", "error");
    }

    axios.get(`/api/view-cart`).then((res) => {
      if (isMounted) {
        if (res.data.status === 200) {
          setCart(res.data.cart);
          setLoading(false);
        } else if (res.data.status === 401) {
          history.push("/");
          swal("Warning", res.data.message, "error");
        }
      }
    });

    // axios.get(`/api/view-cart`).then((res) => {
    //   if (res.data.status === 200) {
    //     setCart(res.data.cart);

    //   }
    // });

    return () => {
      isMounted = false;
    };
  }, [history]);

  // Decrement
  const handleDecrement = (cart_id) => {
    setCart((cart) =>
      cart.map((item) =>
        cart_id === item.id
          ? {
              ...item,
              product_qty: item.product_qty - (item.product_qty > 1 ? 1 : 0),
            }
          : item
      )
    );
    updateCartQuantity(cart_id, "dec");
  };

  // Increment
  const handleIncrement = (cart_id) => {
    setCart((cart) =>
      cart.map((item) =>
        cart_id === item.id
          ? {
              ...item,
              product_qty: item.product_qty + (item.product_qty < 5 ? 1 : 0),
            }
          : item
      )
    );
    updateCartQuantity(cart_id, "inc");
  };

  // UpdateCartQuanity
  function updateCartQuantity(cart_id, scope) {
    axios.put(`/api/cart-updatequantity/${cart_id}/${scope}`).then((res) => {
      if (res.data.status === 200) {
        // swal("Success",res.data.message,"success");
      }
    });
  }

  // Remove cart item
  const deleteCartItem = (e, cart_id) => {
    e.preventDefault();

    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Removing";

    axios.delete(`/api/delete-cartitem/${cart_id}`).then((res) => {
      if (res.data.status === 200) {
        history.push("/cart");
        swal("Success", res.data.cart_data.message, "success");
        thisClicked.closest("a").remove();

        //   ini = res.data.cart_data.productUpdate
        //   var final_ini;

        //   setAllIni(allINI.map( (ini, idx) => {
        //     final_ini = ini.product.selling_price;
        //  }))

        //   final = res.data.cart_data.cartUpdate.product_qty
        //   totalCartPrice +=  final_ini * final;
        //   console.log(final_ini);
        //   console.log(final);
        //   console.log(totalCartPrice);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        thisClicked.innerText = "Remove";
      }
    });
  };

  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <img src={img} width="120px" />
        <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
      </div>
    );
  }
  var cart_HTML = "";
  if (cart.length > 0) {
    cart_HTML = (
      <div>
        {cart.map((item, idx) => {
          // totalCartPrice += item.product.selling_price * item.product_qty;
          totalCartPrice += item.product.selling_price * 1;
          return (
            <>
              <a className="col-md-6 col-lg-12 underlink_removal">
                <div className="row border color-9 mb-3 py-3 mx-0">
                  <div className="col-lg-3">
                    <img
                      className="w-100"
                      src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${item.product.image}`}
                    />
                  </div>
                  <div className="col-lg-6 color-1">
                    <h5 className="fs-0">Details</h5>
                    <hr className="color-9" />

                    <div className="row ">
                      <div className="col-12 mb-3">
                        Room :{" "}
                        <span className="fw-600 mr-3 fs--2">
                          <div className="badge badge-warning fs--2">
                            {item.product.name}
                          </div>
                        </span>
                      </div>
                    </div>

                    <p className="text-dark fs--1 fw-300">
                      {item.product.description}
                    </p>

                    <div className="row align-items-center">
                      <label className="col-3 mb-0" for="exampleInputName">
                        No of Occupants Expected :
                      </label>
                      <div className="col-6">
                        {/* <select className="form-control d-inline-block" style="width: 60px; height: auto;"> */}
                        <div className="form-control d-inline-block">
                          <div className="input-group">
                            <button
                              type="button"
                              onClick={() => handleDecrement(item.id)}
                              className="input-group-text"
                            >
                              -
                            </button>
                            <div className="form-control text-center">
                              {item.product_qty}
                            </div>
                            <button
                              type="button"
                              onClick={() => handleIncrement(item.id)}
                              className="input-group-text"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <p className="fs--1 mb-0 fw-600 color-5">
                          {item.product.selling_price}
                          <span className="color-5 fs--1 fw-400">/ night</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 color-1">
                    <h5 className="fs-0">Price</h5>
                    <hr className="color-9" />
                    <p className="mb-0">NGN {item.product.selling_price}</p>
                    <button
                      type="button"
                      onClick={(e) => deleteCartItem(e, item.id)}
                      className="btn mt-2  fs--1 color-vh-button"
                      href="#"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </a>
            </>
          );
        })}
      </div>
    );
  } else {
    cart_HTML = (
      <div className="">
        <div className="py-3 ">
          <div className="container text-center ">
            <h4 className="display-5 mb-0">No Property Booked Yet</h4>
            <LazyLoadImage
              alt="error_thumbnail"
              className=""
              effect="blur"
              width="85%"
              src={error_image}
            />
          </div>
        </div>
      </div>
    );
  }

  var total_price_checker = "";
  if (cart.length > 0) {
    total_price_checker = (
      <div>
        {cart.map((item, idx) => {
          // totalCartPrice += item.product.selling_price * 1;

          return (
            <div className="row color-1" key={idx}>
              <div className="col-8">
                <p className="mb-0">Sub Total:</p>
              </div>
              <div className="col-4 text-right">
                <p className="mb-0">{item.product.selling_price}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  // BUTTONS
  var total_button = "";
  if (cart.length > 0) {
    total_button = (
      <>
        <Link to="/checkout" className="btn  btn-block mt-3 color-vh-button">
          <div className="col-12 px-0">Proceed to Payment</div>
        </Link>
        <Link to="/search-homes" className="btn btn-dark btn-block mt-3">
          <div className="col-12 px-0">See Available Property</div>
        </Link>
      </>
    );
  } else {
    total_button = (
      <>
        <Link to="/search-homes" className="btn btn-dark btn-block mt-3">
          <div className="col-12 px-0">See Available Property</div>
        </Link>
      </>
    );
  }

  return (
    <div>
      <Navbar />
      <section className="p-0 color-white font-1">
        <div className="background-holder overlay overlay-1">
          {/* className="background-holder overlay overlay-gradient"> */}{" "}
        </div>
        <div className="container">
          <div className="row h-half align-items-center py-5 text-center justify-content-center">
            <div className="col-md-8 col-lg-6">
            <h1 className=" fs-md-4 mb-3 color-vhospitalilty">
                <strong>MY BOOKINGS</strong>
              </h1>
              <p className="lead fs-3">Simple bookings, easy living</p>
            </div>
          </div>
        </div>
      </section>
      <div className="alert alert-dark" role="alert">
        <div className="container">
          <h6 className="">
            <Link className="underlink_removal" to={`/`}>
              Home
            </Link>{" "}
            <span class='icon-Arrow-Right2'></span> My Bookings
          </h6>
        </div>
      </div>

      <div className="py-2">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-12  my-3"></div> */}
            <div className="col-md-12">
              <section className="pt-1 pb-4">
                <div className="container">
                  <h1 className="fs-2">My Bookings </h1>
                </div>
              </section>
              <section className="pt-0 font-1">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-9">
                      <div className="row">
                        {/* REmove */}
                        {cart_HTML}
                        <div className="row mb-8 text-center">
                          <div className="float-left">
                          <Link to="/search-homes" className="btn  btn-lg mt-0 btn-dark underlink_removal">
                                      Back to Browse Property
                                    </Link>
                          </div>
                        </div>
                        {/* REmove */}
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col-12 border color-9 align-self-start">
                          <p className="fw-600 my-3 color-1">
                            You have {cart.length} items in Booking List
                          </p>
                          <hr className="color-9" />
                          {/* <div className="form-check mb-4 color-1">
                            <label className="form-check-label">
                              <input
                                className="form-check-input mr-2"
                                type="checkbox"
                                checked
                              />
                              Gift wrap for &dollar;2{" "}
                            </label>
                          </div> */}
                          <div className="row color-1">
                            {total_price_checker}

                            <div className="col-12">
                              <hr className="color-9 my-2" />{" "}
                            </div>

                            <div className="col-8">
                              <strong>
                                <p className="mb-0">Total:</p>
                              </strong>
                            </div>
                            <div className="col-4 text-right">
                              <strong>
                                <p className="mb-0">{totalCartPrice}</p>
                              </strong>
                            </div>

                            <div className="col-12">
                              <hr className="color-9 mt-2" />{" "}
                            </div>
                            <div className="col-8">
                              <p className="fw-600 color-warning">
                                Payable Total:
                              </p>
                            </div>
                            <div className="col-4 text-right">
                              <p className="fw-600 color-warning">
                                {totalCartPrice}
                              </p>
                            </div>
                          </div>
                        </div>

                        {total_button}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Cart;
